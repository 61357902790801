.container {
  // チェックアウト画面のProgressBarと被らないように調整
  margin-bottom: 48px;
}

.common {
  padding: 8px !important;
  border-radius: 5px !important;
  min-width: 350px !important;
  font-size: 14px !important;
}

.success {
  background-color: #5a5a5a !important;
  color: #fff !important;
}

.error {
  background-color: #ff7043 !important;
  color: #fff !important;
}
