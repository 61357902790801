.oneClickDone {
  width: calc(100vw - 40px);
  max-width: 313px;
  background-color: #5a5a5a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding: 7.5px 2px !important;
}

.oneClickDoneLink {
  border-bottom: 1px solid;
  font-size: 14px;
}
