$base-yellow-color: #fac83c;

.select {
  &Quantity {
    display: table;
    width: 100%;
    @media screen and (min-width: 1036px) {
      display: block;
      border: 1px solid #c8c8c8;
      border-radius: 4px;
      padding: 15px;
    }

    dt,
    dd {
      display: table-cell;
      vertical-align: top;
    }

    dt {
      width: 150px;
      @media screen and (min-width: 1036px) {
        display: block;
        width: 100%;
        padding-bottom: 10px;
      }

      span {
        &:first-child {
          display: block;
          @media screen and (min-width: 1036px) {
            display: inline-block;
            margin-right: 7px;
          }
        }

        &:nth-child(3) {
          margin-left: 5px;
        }
      }
    }

    dd {
      width: calc(100% - 150px);
      @media screen and (min-width: 1036px) {
        display: block;
        width: 100%;
      }
    }
    &Scroll {
      position: fixed;
      top: 70px;
      left: 0;
      right: 0;
      width: 165px !important;
      margin: 5px auto 0;
      z-index: 10;
      background-color: $base-yellow-color;
      border-radius: 30px;
      padding: 7.5px 20px;
      white-space: nowrap;
      font-weight: 700;

      &Visible {
        visibility: visible;
      }

      &Hidden {
        visibility: hidden;
      }
    }
  }
}

.selector {
  width: 90px !important;
  margin: 5px 0 !important;
}

.angleRight {
  margin-left: 5px;
}

.mypageEditButton {
  font-size: 1rem; // 16px
  width: 100%;
}

.oneClick {
  background-color: #fac83a;
  border-radius: 5px;
  display: flex !important;
  width: calc(100vw - 40px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
  padding: 7.5px 20px;
}

.oneClickIfSubscription {
  display: inline-block;
  max-width: calc(100vw - 205px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.floatingCartNotification {
  position: fixed;
  width: calc(100vw - 40px);
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 10;
  background-color: #5a5a5a;
  display: flex;
  margin: auto;
  align-items: center;
  padding: 12px 20px;
  border-radius: 5px;
  & > div {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.hidden {
  position: relative;
  display: block;
  overflow: hidden;
  height: 36px;
  transition: 0.3s ease all;
  > div {
    position: absolute;
    width: 100%;
    top: 0;
    transition: 0.3s ease all;
  }
}

.on {
  height: 0;
  > div {
    top: -50px;
  }
}

.newline {
  white-space: pre-wrap;
}
