.oneClickChange {
  border-bottom: 1px solid;
  line-height: 1;
  font-size: 14px;
}

.oneClickChangeText {
  display: inline-block;
  max-width: calc(100vw - 180px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
