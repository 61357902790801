.selectQuantityScroll {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 165px !important;
  margin: 5px auto 0;
  z-index: 10;
  background-color: #fac83c;
  border-radius: 30px;
  padding: 7.5px 20px;
  white-space: nowrap;
  font-weight: 700;

  &Visible {
    visibility: visible;
  }

  &Hidden {
    visibility: hidden;
  }
}

.oneClick {
  background-color: #fac83a;
  border-radius: 5px;
  display: flex !important;
  width: calc(100vw - 40px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
  align-items: center;
  z-index: 10;
  justify-content: space-between;
  padding: 7.5px 20px;
}

.oneClickIfSubscription {
  display: inline-block;
  max-width: calc(100vw - 205px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.floatingCartNotification {
  position: fixed;
  width: calc(100vw - 40px);
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 10;
  background-color: #5a5a5a;
  display: flex;
  margin: auto;
  align-items: center;
  padding: 12px 20px;
  border-radius: 5px;
  & > div {
    width: 100% !important;
    max-width: 100% !important;
  }
}
